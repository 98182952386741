{
  "description": {
    "p1": "There are two sets of questions below. The first is about your work history and is the perfect space to share things that are a little more like a resume, such as the places you’ve worked, positions you’ve held, and important experiences you gained there.",
    "p2": "The second set of questions are designed to highlight aspects of your personality and practice that will help a prospective patient get to know you.",
    "p3": "Answer these as though a prospective client just asked you the questions. Don’t talk over them with lots of jargon. Speak to their concerns. Respond in a way that showcases your personality and style. Be descriptive but concise."
  },
  "form": {
    "choosePersonalQuestion": "Choose Personal Questions",
    "workHistory": "Work History",
    "workHistory-description": "Write your work experience in a way that highlights where your areas of expertise and specialties were developed. Avoid writing your work history in a resume format or using resume language. Use language that you would use if speaking to a potential client (easy to understand, limit clinical jargon).",
    "chooseQuestionsDescription": "Select a question from the drop down then enter your answer in the field that appears below. Remember to click “Save” at the bottom of the page. Then select the next question you wish to answer, it will appear at the bottom of the page. You may select and answer as many questions as you’d like."
  },
  "title": "Therapist Profile Questions"
}
